@font-face {
  font-family: LI;
  src: url('//static2.qcplay.com/fonts/LI.ttf');
  font-family: "Cordia New Bold";
  src: url('//static2.qcplay.com/fonts/Cordia New Bold.ttf');
}
@media screen and (min-width: 2000px) {
  body,
  html {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.15);
    max-height: 1080px;
  }
}
body,
html {
  max-width: 1920px;
  min-width: 1024px;
  margin: 0 auto;
  height: 100%;
  width: 100%;
}
a:active {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.font-h {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
img {
  display: block;
  width: 100%;
  height: 100%;
}
.aniBtnHover .ani-btn:hover {
  -webkit-animation: ani4 0.2s ease-in-out;
  -moz-animation: ani4 0.2s ease-in-out;
  -ms-animation: ani4 0.2s ease-in-out;
  -o-animation: ani4 0.2s ease-in-out;
  animation: ani4 0.2s ease-in-out;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
}
.ani-btn:hover {
  -webkit-animation: ani4 0.2s ease-in-out;
  -moz-animation: ani4 0.2s ease-in-out;
  -ms-animation: ani4 0.2s ease-in-out;
  -o-animation: ani4 0.2s ease-in-out;
  animation: ani4 0.2s ease-in-out;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
}
.header {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 62px;
  min-width: 1200px;
  z-index: 11;
  background: url("/official/images/pc/header_bg.png") no-repeat;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.header .header-box {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header .header-box .icon {
  float: left;
  width: 190px;
  height: 46px;
  margin-left: 20px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.header .header-box .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.header .header-box .nav {
  float: right;
  height: 52px;
  margin-right: 50px;
}
.header .header-box .nav ul {
  display: block;
  width: 100%;
  height: 68px;
  display: flex;
  justify-content: flex-end;
}
.header .header-box .nav ul li {
  cursor: pointer;
}
.header .header-box .nav .ani-btn {
  position: relative;
  display: block;
  height: 68px;
  padding: 0 10px;
  color: #503d49;
  line-height: 58px;
  font-size: 18px;
  text-align: center;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.header .header-box .nav .ani-btn span {
  float: left;
  padding-left: 60px;
  color: #6f5a57;
  font-size: 24px;
  font-family: LI;
  font-weight: 500;
}
.header .header-box .nav .ani-btn:after {
  position: absolute;
  top: 8px;
  left: 30px;
  width: 35px;
  height: 35px;
  background: url("/official/images/pc/snail_ke.png") 12px 9px no-repeat;
  -webkit-background-size: 65% 65%;
  -moz-background-size: 65% 65%;
  -ms-background-size: 65% 65%;
  -o-background-size: 65% 65%;
  background-size: 65% 65%;
  content: '';
}
.header .header-box .nav .on:after {
  background: url("/official/images/pc/snail.png") no-repeat;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -ms-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
}
.header .header-box .nav .ani-btn:hover span,
.header .header-box .nav .on span {
  text-shadow: 1px 1px 0px #c46179, -1px -1px 0px #c46179, -1px 1px 0px #c46179, 1px -1px 0px #c46179, 1px 1px 0px #c46179, -1px -1px 0px #c46179, -1px 1px 0px #c46179, 1px -1px 1px #c46179, 1px 1px 1px #c46179, -1px -1px 1px #c46179, -1px 1px 1px #c46179, 1px -1px 1px #c46179, 1px 1px 1px #c46179, -1px -1px 1px #c46179, -1px 1px 1px #c46179, 1px -1px 1px #c46179;
  color: #f5e6e6;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}
.header .header-box .ani-btn:hover .hidden-info {
  display: block;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}
.header .hidden-link-box {
  display: none;
  position: absolute;
  top: 58px;
  right: -9px;
  width: 195px;
  height: 225px;
  background: url("/official/images/pc/hidden_box.png") no-repeat;
}
.header .hidden-link-box div {
  padding-top: 60px;
}
.header .hidden-link-box div a {
  position: relative;
  width: 100%;
  height: 45px;
  color: #786c61;
  line-height: 44px;
  font-size: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  font-weight: 500;
}
.header .hidden-link-box div a i {
  display: block;
  width: 31px;
  height: 31px;
  margin: 0 10px 0 40px;
  background: url("/official/images/pc/nav_btn.png") no-repeat;
}
.header .hidden-link-box div a:nth-child(1) i {
  background-position: 0px 0px;
}
.header .hidden-link-box div a:nth-child(2) i {
  background-position: 0px -40px;
}
.header .hidden-link-box div a:hover {
  color: #de3b5e;
}
.header .hidden-link-box div a:nth-last-child(1):after {
  background: none;
}
.ts-select-span span {
  text-shadow: 1px 1px 0 #a16c76, -1px -1px 0 #a16c76, -1px 1px 0 #a16c76, 1px -1px 0 #a16c76, 1px 1px 0 #a16c76, -1px -1px 0 #a16c76, -1px 1px 0 #a16c76, 1px -1px 1px #a16c76, 1px 1px 1px #a16c76, -1px -1px 1px #a16c76, -1px 1px 1px #a16c76, 1px -1px 1px #a16c76, 1px 1px 1px #a16c76, -1px -1px 1px #a16c76, -1px 1px 1px #a16c76, 1px -1px 1px #a16c76;
  color: #faece8;
}
.ts-select-on {
  background: url("/official/images/pc/snail.png") no-repeat;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -ms-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
}
.header-bg {
  height: 58px;
  background: url("/official/images/pc/header_bg.png") 50% no-repeat;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -ms-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
}
.header-bg .header-box {
  height: 58px;
  padding-top: 0;
}
.header-bg .icon {
  margin-top: 0;
}
.header-bg .nav ul .ani-btn span {
  color: #503d49;
}
.header-bg .nav ul .on span,
.header-bg .nav ul .ani-btn:hover span {
  text-shadow: 1px 1px 0 #a16c76, -1px -1px 0 #a16c76, -1px 1px 0 #a16c76, 1px -1px 0 #a16c76, 1px 1px 0 #a16c76, -1px -1px 0 #a16c76, -1px 1px 0 #a16c76, 1px -1px 1px #a16c76, 1px 1px 1px #a16c76, -1px -1px 1px #a16c76, -1px 1px 1px #a16c76, 1px -1px 1px #a16c76, 1px 1px 1px #a16c76, -1px -1px 1px #a16c76, -1px 1px 1px #a16c76, 1px -1px 1px #a16c76;
  color: #faece8;
}
.header-dh .nav ul .nav-link-01 span {
  text-shadow: 1px 1px 0 #a16c76, -1px -1px 0 #a16c76, -1px 1px 0 #a16c76, 1px -1px 0 #a16c76, 1px 1px 0 #a16c76, -1px -1px 0 #a16c76, -1px 1px 0 #a16c76, 1px -1px 1px #a16c76, 1px 1px 1px #a16c76, -1px -1px 1px #a16c76, -1px 1px 1px #a16c76, 1px -1px 1px #a16c76, 1px 1px 1px #a16c76, -1px -1px 1px #a16c76, -1px 1px 1px #a16c76, 1px -1px 1px #a16c76;
  color: #faece8;
}
.header-dh .nav ul .nav-link-01:after {
  background: url("/official/images/pc/snail.png") no-repeat;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -ms-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
}
.header-zx .nav ul .nav-link-02 span {
  text-shadow: 1px 1px 0 #a16c76, -1px -1px 0 #a16c76, -1px 1px 0 #a16c76, 1px -1px 0 #a16c76, 1px 1px 0 #a16c76, -1px -1px 0 #a16c76, -1px 1px 0 #a16c76, 1px -1px 1px #a16c76, 1px 1px 1px #a16c76, -1px -1px 1px #a16c76, -1px 1px 1px #a16c76, 1px -1px 1px #a16c76, 1px 1px 1px #a16c76, -1px -1px 1px #a16c76, -1px 1px 1px #a16c76, 1px -1px 1px #a16c76;
  color: #faece8;
}
.header-zx .nav ul .nav-link-02:after {
  background: url("/official/images/pc/snail.png") no-repeat;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -ms-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
}
.header-gl .nav ul .nav-link-05 span {
  text-shadow: 1px 1px 0 #a16c76, -1px -1px 0 #a16c76, -1px 1px 0 #a16c76, 1px -1px 0 #a16c76, 1px 1px 0 #a16c76, -1px -1px 0 #a16c76, -1px 1px 0 #a16c76, 1px -1px 1px #a16c76, 1px 1px 1px #a16c76, -1px -1px 1px #a16c76, -1px 1px 1px #a16c76, 1px -1px 1px #a16c76, 1px 1px 1px #a16c76, -1px -1px 1px #a16c76, -1px 1px 1px #a16c76, 1px -1px 1px #a16c76;
  color: #faece8;
}
.header-gl .nav ul .nav-link-05:after {
  background: url("/official/images/pc/snail.png") no-repeat;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -ms-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
}
.header-st .nav ul .nav-link-04 span {
  text-shadow: 1px 1px 0 #a16c76, -1px -1px 0 #a16c76, -1px 1px 0 #a16c76, 1px -1px 0 #a16c76, 1px 1px 0 #a16c76, -1px -1px 0 #a16c76, -1px 1px 0 #a16c76, 1px -1px 1px #a16c76, 1px 1px 1px #a16c76, -1px -1px 1px #a16c76, -1px 1px 1px #a16c76, 1px -1px 1px #a16c76, 1px 1px 1px #a16c76, -1px -1px 1px #a16c76, -1px 1px 1px #a16c76, 1px -1px 1px #a16c76;
  color: #faece8;
}
.header-st .nav ul .nav-link-04:after {
  background: url("/official/images/pc/snail.png") no-repeat;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -ms-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
}
.footer {
  position: relative;
  width: 100%;
  background: #282f3e;
  z-index: 9;
}
.footer .footer-content {
  overflow: hidden;
  min-width: 1200px;
  padding: 45px 0;
  margin: 0 auto;
  color: #fff;
  font-size: 12px;
  line-height: 40px;
}
.footer .footer-content .left-logo {
  position: relative;
  float: left;
  display: block;
  width: 110px;
  height: 110px;
  margin-right: 25px;
}
.footer .footer-content .centre-info {
  float: left;
}
.footer .footer-content .centre-info .btn-box {
  display: flex;
  height: 54px;
  padding-bottom: 10px;
}
.footer .footer-content .centre-info .btn-box a {
  display: block;
  height: 100%;
}
.footer .footer-content .centre-info .btn-box .btn-1 {
  width: 144px;
  margin-right: 37px;
  background: url("/official/images/pc/icon_all.png") -156px -828px no-repeat;
}
.footer .footer-content .centre-info .btn-box .btn-2 {
  width: 181px;
  background: url("/official/images/pc/icon_all.png") -315px -828px no-repeat;
}
.footer .footer-content .centre-info .info {
  padding-top: 23px;
  color: #fff;
  line-height: 32px;
  font-size: 12px;
}
.footer .footer-content .right-details {
  float: right;
  text-align: right;
  line-height: 32px;
  margin-top: 21px;
}
.footer .footer-content .right-details p,
.footer .footer-content .right-details a {
  color: #fff;
  font-size: 12px;
}
.footer .footer-content .right-details a:hover {
  text-decoration: underline;
}
.select {
  right: -243px;
}
.select .changes-btn .on {
  display: none;
  left: -78px;
}
.select .changes-btn .off {
  display: block;
  left: 0;
}
.index {
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
  filter: alpha(opacity=0);
}
.index .swiper-container {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.index .swiper-slide {
  overflow: hidden;
}
.index .swiper-slide .bg .enclosure .flame i {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.index .swiper-slide .bg .enclosure .disappear {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
.index .content-01 {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}
.index .content-01 .banner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -265px;
  width: 821px;
  height: 600px;
  transform: translateX(-50%);
  z-index: 9;
}
.index .content-01 .banner .title {
  width: 821px;
}
.index .content-01 .banner .title img {
  display: block;
  width: 100%;
  height: 100%;
}
.index .content-01 .banner .title .play-btn {
  position: absolute;
  top: 146px;
  left: 34px;
  width: 92px;
  height: 97px;
}
.index .content-01 .banner .title .play-btn i {
  display: block;
  width: 100%;
  height: 100%;
  background: url("/official/images/pc/play_btn.png") no-repeat;
  -webkit-animation: playBtnAni 1.5s ease-in-out infinite;
  -moz-animation: playBtnAni 1.5s ease-in-out infinite;
  -ms-animation: playBtnAni 1.5s ease-in-out infinite;
  -o-animation: playBtnAni 1.5s ease-in-out infinite;
  animation: playBtnAni 1.5s ease-in-out infinite;
}
.index .content-01 .banner .title .play-btn:hover i {
  animation-play-state: paused;
}
.index .content-01 .banner .code-btn {
  width: 880px;
  margin-left: -24px;
}
.index .content-01 .banner .code-btn .top-code-box {
  position: relative;
  width: 980px;
  height: 121px;
}
.index .content-01 .banner .code-btn .btn-box {
  width: 980px;
  height: 121px;
  margin: 75px auto 35px;
}
.index .content-01 .banner .code-btn .btn-box a {
  float: left;
  display: block;
  width: 240px;
  height: 97px;
  margin-bottom: 18px;
  margin-left: 40px;
}
.index .content-01 .banner .code-btn .btn-box .google-btn {
  background: url("/official/images/pc/google_btn.png") no-repeat;
}
.index .content-01 .banner .code-btn .btn-box .app-btn {
  background: url("/official/images/pc/app_btn.png") no-repeat;
}
.index .content-01 .banner .code-btn .btn-box .vision_btn {
  background: url("/official/images/pc/vision_btn.png") no-repeat;
}
.index .content-01 .banner .code-btn .bottom-code-box {
  width: 420px;
  height: auto;
  margin: 0 auto;
}
.index .content-01 .banner .code-btn .bottom-code-box a {
  float: left;
  width: 131px;
  height: 131px;
  margin: 0 8px 0 0;
}
.index .content-01 .banner .code-btn .bottom-code-box .facebook-btn {
  background: url("/official/images/pc/facebook_btn.png") no-repeat;
}
.index .content-01 .banner .code-btn .bottom-code-box .zalo-btn {
  background: url("/official/images/pc/zalo_btn.png") no-repeat;
}
.index .content-01 .banner .code-btn .bottom-code-box .discord-btn {
  background: url("/official/images/pc/discord_btn.png") no-repeat;
}
.index .content-01 .banner .add-btn-box {
  position: absolute;
  top: 285px;
  left: 150px;
  height: 171px;
}
.index .content-01 .bg {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1920px;
  height: 1080px;
  margin: -540px 0 0 -960px;
  background: url("/official/images/pc/index_bg.png") #B3EBFC bottom center no-repeat;
}
.index .content-01 .bg:after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
}
.index .content-02 {
  position: relative;
  width: 100%;
  background: url("/official/images/pc/content_bg_02_02.png") top no-repeat;
}
.index .content-02 .separate {
  overflow: hidden;
  position: absolute;
  top: -3px;
  width: 100%;
  height: 169px;
  background: url("/official/images/pc/pipeline.png") 0 -25px no-repeat;
  z-index: 13;
}
.index .content-02 .separate span {
  position: absolute;
  display: block;
  width: 1920px;
  height: 135px;
  margin-top: 1px;
  background: url("/official/images/pc/pipeline_03.png") center no-repeat;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -ms-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  z-index: 3;
}
.index .content-02 .separate b {
  position: absolute;
  top: 63px;
  left: 50%;
  width: 68px;
  height: 44px;
  z-index: 2;
}
.index .content-02 .separate .mouse-01 {
  margin-left: 960px;
  -webkit-animation: mouse 10s ease-in-out infinite;
  -moz-animation: mouse 10s ease-in-out infinite;
  -ms-animation: mouse 10s ease-in-out infinite;
  -o-animation: mouse 10s ease-in-out infinite;
  animation: mouse 10s ease-in-out infinite;
}
.index .content-02 .separate .mouse-01 i {
  display: block;
  width: 100%;
  height: 100%;
  background: url("/official/images/pc/mouse_01.png") no-repeat;
  -webkit-animation: mouseAni1 0.4s steps(2) infinite;
  -moz-animation: mouseAni1 0.4s steps(2) infinite;
  -ms-animation: mouseAni1 0.4s steps(2) infinite;
  -o-animation: mouseAni1 0.4s steps(2) infinite;
  animation: mouseAni1 0.4s steps(2) infinite;
}
.index .content-02 .separate .mouse-02 {
  margin-left: 1050px;
  -webkit-animation: mouse 10s ease-in-out infinite;
  -moz-animation: mouse 10s ease-in-out infinite;
  -ms-animation: mouse 10s ease-in-out infinite;
  -o-animation: mouse 10s ease-in-out infinite;
  animation: mouse 10s ease-in-out infinite;
}
.index .content-02 .separate .mouse-02 i {
  display: block;
  width: 100%;
  height: 100%;
  background: url("/official/images/pc/mouse_02.png") no-repeat;
  -webkit-animation: mouseAni1 0.4s steps(2) infinite;
  -moz-animation: mouseAni1 0.4s steps(2) infinite;
  -ms-animation: mouseAni1 0.4s steps(2) infinite;
  -o-animation: mouseAni1 0.4s steps(2) infinite;
  animation: mouseAni1 0.4s steps(2) infinite;
}
.index .content-02 .title-02 {
  position: absolute;
  top: -30px;
  left: 50%;
  width: 388px;
  height: 324px;
  margin-left: -194px;
  background: url("/official/images/pc/content_title_02.png") no-repeat;
  z-index: 12;
  transform: scale(0.85);
}
.index .content-02 .main {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1349px;
  height: 724px;
  margin-left: -674.5px;
  margin-top: -320px;
  z-index: 11;
  transform: scale(0.85);
}
.index .content-02 .main .map-box {
  width: 1349px;
  height: 724px;
  background: url("/official/images/pc/milestone/map_bg.png") no-repeat;
}
.index .content-02 .main .map-box .move-snail {
  position: absolute;
  top: 333px;
  left: 123px;
  width: 86px;
  height: 100px;
  background: url("/official/images/pc/milestone/move_snail.png") no-repeat;
  z-index: 2;
  -webkit-animation: snailAni 2s ease-in-out infinite;
  -moz-animation: snailAni 2s ease-in-out infinite;
  -ms-animation: snailAni 2s ease-in-out infinite;
  -o-animation: snailAni 2s ease-in-out infinite;
  animation: snailAni 2s ease-in-out infinite;
}
.index .content-02 .main .map-box .remark {
  position: absolute;
  bottom: 35px;
  left: 50px;
  width: 458px;
  height: 24px;
  background: url("/official/images/pc/milestone/remark.png") no-repeat;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -ms-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
}
.index .content-02 .main .map-box .award-01 {
  position: absolute;
  top: 320px;
  left: 240px;
  width: 244px;
  height: 212px;
  background: url("/official/images/pc/milestone/award_01_01.png") no-repeat;
  cursor: pointer;
}
.index .content-02 .main .map-box .award-02 {
  position: absolute;
  top: 135px;
  left: 440px;
  width: 242px;
  height: 207px;
  background: url("/official/images/pc/milestone/award_02_01.png") no-repeat;
  cursor: pointer;
}
.index .content-02 .main .map-box .award-03 {
  position: absolute;
  top: 335px;
  left: 620px;
  width: 269px;
  height: 280px;
  background: url("/official/images/pc/milestone/award_03_01.png") no-repeat;
  cursor: pointer;
}
.index .content-02 .main .map-box .award-05 {
  position: absolute;
  top: 20px;
  left: 750px;
  width: 380px;
  height: 380px;
  background: url("/official/images/pc/milestone/award_05_01.png") no-repeat;
  cursor: pointer;
}
.index .content-02 .main .map-box .award-06 {
  position: absolute;
  top: 310px;
  left: 860px;
  width: 493.2px;
  height: 360.9px;
  background: url("/official/images/pc/milestone/award_06_01.png") no-repeat;
  cursor: pointer;
  background-size: contain !important;
}
.index .content-02 .main .got-01 .award-01 {
  position: absolute;
  top: 320px;
  left: 240px;
  width: 244px;
  height: 212px;
  background: url("/official/images/pc/milestone/award_01_02.png") no-repeat;
}
.index .content-02 .main .got-02 .award-01 {
  position: absolute;
  top: 320px;
  left: 240px;
  width: 244px;
  height: 212px;
  background: url("/official/images/pc/milestone/award_01_02.png") no-repeat;
}
.index .content-02 .main .got-02 .award-02 {
  position: absolute;
  top: 135px;
  left: 440px;
  width: 242px;
  height: 207px;
  background: url("/official/images/pc/milestone/award_02_02.png") no-repeat;
}
.index .content-02 .main .got-03 .award-01 {
  position: absolute;
  top: 320px;
  left: 240px;
  width: 244px;
  height: 212px;
  background: url("/official/images/pc/milestone/award_01_02.png") no-repeat;
}
.index .content-02 .main .got-03 .award-02 {
  position: absolute;
  top: 135px;
  left: 440px;
  width: 242px;
  height: 207px;
  background: url("/official/images/pc/milestone/award_02_02.png") no-repeat;
}
.index .content-02 .main .got-03 .award-03 {
  position: absolute;
  top: 335px;
  left: 620px;
  width: 269px;
  height: 280px;
  background: url("/official/images/pc/milestone/award_03_02.png") no-repeat;
}
.index .content-02 .main .got-04 .award-01 {
  position: absolute;
  top: 320px;
  left: 240px;
  width: 244px;
  height: 212px;
  background: url("/official/images/pc/milestone/award_01_02.png") no-repeat;
}
.index .content-02 .main .got-04 .award-02 {
  position: absolute;
  top: 135px;
  left: 440px;
  width: 242px;
  height: 207px;
  background: url("/official/images/pc/milestone/award_02_02.png") no-repeat;
}
.index .content-02 .main .got-04 .award-03 {
  position: absolute;
  top: 335px;
  left: 620px;
  width: 269px;
  height: 280px;
  background: url("/official/images/pc/milestone/award_03_02.png") no-repeat;
}
.index .content-02 .main .got-04 .award-05 {
  position: absolute;
  top: 20px;
  left: 750px;
  width: 380px;
  height: 380px;
  background: url("/official/images/pc/milestone/award_05_02.png") no-repeat;
}
.index .content-02 .main .got-05 .award-01 {
  position: absolute;
  top: 320px;
  left: 240px;
  width: 244px;
  height: 212px;
  background: url("/official/images/pc/milestone/award_01_02.png") no-repeat;
}
.index .content-02 .main .got-05 .award-02 {
  position: absolute;
  top: 135px;
  left: 440px;
  width: 242px;
  height: 207px;
  background: url("/official/images/pc/milestone/award_02_02.png") no-repeat;
}
.index .content-02 .main .got-05 .award-03 {
  position: absolute;
  top: 335px;
  left: 620px;
  width: 269px;
  height: 280px;
  background: url("/official/images/pc/milestone/award_03_02.png") no-repeat;
}
.index .content-02 .main .got-05 .award-05 {
  position: absolute;
  top: 20px;
  left: 750px;
  width: 380px;
  height: 380px;
  background: url("/official/images/pc/milestone/award_05_02.png") no-repeat;
}
.index .content-02 .main .got-05 .award-06 {
  position: absolute;
  top: 310px;
  left: 860px;
  width: 493.2px;
  height: 360.9px;
  background: url("/official/images/pc/milestone/award_06_02.png") no-repeat;
  background-size: contain !important;
}
.index .content-02 .enclosure {
  position: absolute;
  bottom: -42px;
  left: 50%;
  width: 1920px;
  height: 161px;
  margin-left: -960px;
  z-index: 12;
}
.index .content-02 .enclosure .ghost {
  position: absolute;
  bottom: 500px;
  left: 50%;
  width: 159px;
  height: 173px;
  margin-left: -748px;
  z-index: 9;
  background: url("/official/images/pc/milestone/ghost.png") no-repeat;
  -webkit-animation: ghostAni 1.5s ease-in-out infinite;
  -moz-animation: ghostAni 1.5s ease-in-out infinite;
  -ms-animation: ghostAni 1.5s ease-in-out infinite;
  -o-animation: ghostAni 1.5s ease-in-out infinite;
  animation: ghostAni 1.5s ease-in-out infinite;
}
.index .content-02 .enclosure span {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 1920px;
  height: 161px;
  background: url("/official/images/pc/seabed_img_02.png") no-repeat;
  z-index: 2;
}
.index .content-02 .enclosure .treasure-01 {
  position: absolute;
  bottom: 82px;
  width: 292px;
  height: 212px;
  left: 50%;
  margin-left: -873px;
  background: url("/official/images/pc/milestone/treasure.png") no-repeat;
  z-index: 1;
}
.index .content-02 .treasure-02 {
  position: absolute;
  bottom: 23px;
  width: 376px;
  height: 348px;
  left: 50%;
  margin-left: 437px;
  background: url("/official/images/pc/milestone/dgl_snail.png") no-repeat;
  z-index: 11;
}
.index .content-04 {
  position: relative;
  width: 100%;
}
.index .content-04 .separate {
  overflow: hidden;
  position: absolute;
  top: -10px;
  width: 100%;
  height: 169px;
  background: url("/official/images/pc/pipeline.png") 0 -25px no-repeat;
  z-index: 10;
}
.index .content-04 .separate span {
  position: absolute;
  display: block;
  width: 100%;
  height: 186px;
  margin-top: -52px;
  background: url("/official/images/pc/pipeline_04.png") center no-repeat;
  z-index: 3;
}
.index .content-04 .separate b {
  position: absolute;
  top: 63px;
  left: 50%;
  width: 68px;
  height: 44px;
  z-index: 2;
}
.index .content-04 .separate .mouse-01 {
  margin-left: 960px;
  -webkit-animation: mouse 10s ease-in-out infinite;
  -moz-animation: mouse 10s ease-in-out infinite;
  -ms-animation: mouse 10s ease-in-out infinite;
  -o-animation: mouse 10s ease-in-out infinite;
  animation: mouse 10s ease-in-out infinite;
}
.index .content-04 .separate .mouse-01 i {
  display: block;
  width: 100%;
  height: 100%;
  background: url("/official/images/pc/mouse_01.png") no-repeat;
  -webkit-animation: mouseAni1 0.4s steps(2) infinite;
  -moz-animation: mouseAni1 0.4s steps(2) infinite;
  -ms-animation: mouseAni1 0.4s steps(2) infinite;
  -o-animation: mouseAni1 0.4s steps(2) infinite;
  animation: mouseAni1 0.4s steps(2) infinite;
}
.index .content-04 .separate .mouse-02 {
  margin-left: 1050px;
  -webkit-animation: mouse 10s ease-in-out infinite;
  -moz-animation: mouse 10s ease-in-out infinite;
  -ms-animation: mouse 10s ease-in-out infinite;
  -o-animation: mouse 10s ease-in-out infinite;
  animation: mouse 10s ease-in-out infinite;
}
.index .content-04 .separate .mouse-02 i {
  display: block;
  width: 100%;
  height: 100%;
  background: url("/official/images/pc/mouse_02.png") no-repeat;
  -webkit-animation: mouseAni1 0.4s steps(2) infinite;
  -moz-animation: mouseAni1 0.4s steps(2) infinite;
  -ms-animation: mouseAni1 0.4s steps(2) infinite;
  -o-animation: mouseAni1 0.4s steps(2) infinite;
  animation: mouseAni1 0.4s steps(2) infinite;
}
.index .content-04 .title {
  position: absolute;
  left: 50%;
  top: 0;
  width: 394px;
  height: 281px;
  margin-left: -197px;
  background: url("/official/images/pc/content_title_04.png") no-repeat;
  z-index: 9;
}
.index .content-04 .bg {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 1920px;
  height: 100%;
  margin-left: -960px;
  background: #72686f url("/official/images/pc/content_bg_04.png") 50% bottom no-repeat;
}
.index .content-04 .enclosure {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 1920px;
  height: 231px;
  margin-left: -960px;
  z-index: 8;
}
.index .content-04 .enclosure span {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 1920px;
  height: 235px;
  background: url("/official/images/pc/seabed_img_04.png") no-repeat;
  z-index: 2;
}
.index .content-04 .enclosure .torch-01,
.index .content-04 .enclosure .torch-02 {
  position: absolute;
  bottom: 143px;
  width: 150px;
  height: 150px;
  left: 50%;
  z-index: 2;
}
.index .content-04 .enclosure .torch-01 i,
.index .content-04 .enclosure .torch-02 i {
  display: block;
  width: 100%;
  height: 100%;
  background: url("/official/images/pc/combination/torch.png");
}
.index .content-04 .enclosure .torch-01 {
  margin-left: -728px;
}
.index .content-04 .enclosure .torch-01 i {
  -webkit-animation: huobaAni 1.1s steps(13) infinite;
  -moz-animation: huobaAni 1.1s steps(13) infinite;
  -ms-animation: huobaAni 1.1s steps(13) infinite;
  -o-animation: huobaAni 1.1s steps(13) infinite;
  animation: huobaAni 1.1s steps(13) infinite;
}
.index .content-04 .enclosure .torch-02 {
  margin-left: 578px;
}
.index .content-04 .enclosure .torch-02 i {
  -webkit-animation: huobaAni 1.1s steps(13) infinite;
  -moz-animation: huobaAni 1.1s steps(13) infinite;
  -ms-animation: huobaAni 1.1s steps(13) infinite;
  -o-animation: huobaAni 1.1s steps(13) infinite;
  animation: huobaAni 1.1s steps(13) infinite;
}
.index .content-04 .amazed-snail {
  position: absolute;
  bottom: 136px;
  left: 291px;
  display: block;
  width: 157px;
  height: 208px;
  background: url("/official/images/pc/amazed_snail.png") no-repeat;
  z-index: 7;
  cursor: pointer;
}
.index .content-04 .rubberBand2 {
  -webkit-animation-name: rubberBand2;
  animation-name: rubberBand2;
  animation-duration: 0.8s;
  -webkit-animation-duration: 0.8s;
  animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
}
.index .content-04 .main {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 710px;
  height: 550px;
  margin: -200px 0 0 -355px;
  z-index: 10;
  transform: scale(0.9);
}
.index .content-04 .main .swiper-container-card {
  position: absolute;
  width: 100%;
  height: 512px;
}
.index .content-04 .main .swiper-container-card .swiper-slide {
  position: relative;
  width: 296px;
  height: 511px;
  opacity: 0;
}
.index .content-04 .main .swiper-container-card .swiper-slide img {
  display: block;
  width: 296px;
  height: 511px;
  object-fit: cover;
}
.index .content-04 .main .swiper-container-card .swiper-slide-active,
.index .content-04 .main .swiper-container-card .swiper-slide-prev,
.index .content-04 .main .swiper-container-card .swiper-slide-next {
  opacity: 1;
}
.index .content-04 .main .swiper-container-card .swiper-slide-active {
  overflow: initial !important;
}
.index .content-04 .main .swiper-container-card .swiper-pagination2 {
  position: absolute;
  display: flex;
  bottom: -9%;
  justify-content: center;
  align-items: center;
}
.index .content-04 .main .swiper-container-card .swiper-pagination2 .swiper-pagination-bullet {
  width: 25px;
  height: 25px;
  background: url("/official/images/pc/postcard/dot_01.png") no-repeat;
  opacity: 1;
  transform: scale(0.8);
  margin: 0;
  margin: 0 5px;
}
.index .content-04 .main .swiper-container-card .swiper-pagination2 .swiper-pagination-bullet-active {
  width: 31px;
  height: 31px;
  background: url("/official/images/pc/postcard/dot_02.png") no-repeat;
  transform: scale(0.8);
}
.index .content-04 .main .swiper-container-card .swiper-button-prev {
  position: absolute;
  top: 46.5%;
  left: -7%;
  width: 53px;
  height: 61px;
  background: url(/official/images/pc/postcard/left_arrow.png) no-repeat;
  animation: playBtnAni 1s ease-in-out infinite;
}
.index .content-04 .main .swiper-container-card .swiper-button-next {
  position: absolute;
  top: 46.5%;
  right: -7%;
  width: 53px;
  height: 61px;
  background: url(/official/images/pc/postcard/right_arrow.png) no-repeat;
  animation: playBtnAni 1s ease-in-out infinite;
}
.index .content-05 {
  width: 100%;
  height: 320px;
  background: black;
  z-index: 12;
}
.index .content-05 .footer-content {
  position: absolute;
  left: 50%;
  width: 96%;
  min-width: 1600px;
  padding: 30px 0;
  transform: translateX(-50%);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  background: black;
  color: #fff;
  font-size: 22px;
  line-height: 30px;
}
.index .content-05 .footer-content .l-logo {
  width: 200px;
  height: 45px;
  display: flex;
}
.index .content-05 .footer-content .l-logo img {
  display: block;
  width: 100%;
  height: 100%;
}
.index .content-05 .footer-content .content p {
  font-size: 16px;
  line-height: 28px;
  color: #fff;
  text-align: center;
  font-family: "Times New Roman";
}
.index .content-05 .footer-content .content p a {
  color: #fff;
}
.index .content-05 .footer-content .content p:nth-child(1) {
  margin-top: 11px;
}
.index .content-05 .footer-content .r-logo {
  width: 89px;
  height: 128px;
  display: flex;
}
.index .content-05 .footer-content .r-logo img {
  display: block;
  width: 100%;
  height: 100%;
}
.index .rocket {
  display: none;
  overflow: hidden;
  position: absolute;
  bottom: 10px;
  right: 22px;
  width: 110px;
  height: 182px;
  z-index: 10;
  -webkit-animation: rocket 4s linear infinite;
  -moz-animation: rocket 4s linear infinite;
  -ms-animation: rocket 4s linear infinite;
  -o-animation: rocket 4s linear infinite;
  animation: rocket 4s linear infinite;
}
.index .rocket .return-btn {
  display: block;
  width: 200px;
  height: 200px;
  margin-left: -40px;
}
.index .rocket .return-btn:after {
  display: block;
  width: 100%;
  height: 100%;
  background: url("/official/images/pc/combination/rocket.png") no-repeat;
  -webkit-animation: rocketAni 1s steps(8) infinite;
  -moz-animation: rocketAni 1s steps(8) infinite;
  -ms-animation: rocketAni 1s steps(8) infinite;
  -o-animation: rocketAni 1s steps(8) infinite;
  animation: rocketAni 1s steps(8) infinite;
  content: '';
}
.index .rocket-ani {
  bottom: 100vh;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}
.index .popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("/official/images/pc/mengban.png");
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -ms-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  z-index: 12;
}
.index .video-wrap .popup-video {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 837px;
  height: 520px;
  margin: -260px 0 0 -418px;
  background: url("/official/images/pc/popup_video.png") no-repeat;
  z-index: 2;
}
.index .video-wrap .popup-video .popup-box {
  overflow: hidden;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 817px;
  height: 500px;
  border-radius: 5px;
  z-index: 9;
}
.index .video-wrap .popup-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.index .video-wrap .popup-bg .close-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 96px;
  height: 94px;
  margin: -280px 0 0 410px;
  cursor: pointer;
}
.index .video-wrap .popup-bg .close-btn:after {
  position: absolute;
  top: 25px;
  left: 27px;
  width: 46px;
  height: 46px;
  background: url("/official/images/pc/close_bg_02.png") no-repeat;
  content: '';
  -webkit-transform: rotate(0deg);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.index .video-wrap .popup-bg .close-btn:hover:after {
  -webkit-transform: rotate(90deg);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.index .wrap30 .popup30 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 493px;
  height: 627px;
  margin: -313.5px 0 0 -246.5px;
  background: url("/official/images/pc/30w.png") no-repeat;
  z-index: 2;
}
.index .wrap30 .popup30 .btn {
  position: absolute;
  width: 170px;
  height: 60px;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
.index .wrap40 .popup40 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 493px;
  height: 627px;
  margin: -313.5px 0 0 -246.5px;
  background: url("/official/images/pc/40w.png") no-repeat;
  z-index: 2;
}
.index .wrap40 .popup40 .btn {
  position: absolute;
  width: 170px;
  height: 60px;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
.index .wrap50 .popup50 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 493px;
  height: 627px;
  margin: -313.5px 0 0 -246.5px;
  background: url("/official/images/pc/50w.png") no-repeat;
  z-index: 2;
}
.index .wrap50 .popup50 .btn {
  position: absolute;
  width: 170px;
  height: 60px;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
.index .wrap60 .popup60 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 493px;
  height: 627px;
  margin: -313.5px 0 0 -246.5px;
  background: url("/official/images/pc/60w.png") no-repeat;
  z-index: 2;
}
.index .wrap60 .popup60 .btn {
  position: absolute;
  width: 170px;
  height: 60px;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
.index .wrap80 .popup80 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 493px;
  height: 627px;
  margin: -313.5px 0 0 -246.5px;
  background: url("/official/images/pc/80w.png") no-repeat;
  z-index: 2;
}
.index .wrap80 .popup80 .btn {
  position: absolute;
  width: 170px;
  height: 60px;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
.index-show {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.preloading {
  overflow-y: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 1920px;
  height: 1080px;
  margin-top: -540px;
  margin-left: -960px;
  background: url("/official/images/pc/index_bg.png") 50% no-repeat;
  z-index: 9999;
}
.preloading .loding_time {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 588px;
  height: 100px;
  margin: 20px 0 0 -294px;
  background: url("/official/images/pc/m_jdt.png") no-repeat;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -ms-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  z-index: 1;
}
.preloading .loding_time div {
  position: absolute;
  bottom: 43px;
  left: 33px;
  width: 509px;
  height: 24px;
}
.preloading .loding_time span {
  position: absolute;
  position: relative;
  display: block;
  width: 10%;
  height: 24px;
  border-radius: 50px;
  background: url("/official/images/pc/m_dt_sz.png");
  -webkit-background-size: auto 100%;
  -moz-background-size: auto 100%;
  -ms-background-size: auto 100%;
  -o-background-size: auto 100%;
  background-size: auto 100%;
}
.preloading .loding_time span:before {
  position: absolute;
  top: -22px;
  right: -1%;
  display: block;
  width: 40px;
  height: 47px;
  background: url("/official/images/pc/m_dt_t.png") no-repeat;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -ms-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  content: '';
}
.preloading .loding_time b {
  position: absolute;
  bottom: -30px;
  left: 0;
  width: 100%;
  color: #fff;
  text-shadow: 1px 1px 1px #7d5f5f, -1px -1px 1px #7d5f5f, 1px -1px 1px #7d5f5f, -1px 1px 1px #7d5f5f;
  font-size: 28px;
  text-align: center;
}
.preloading .bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.32);
  content: "";
}
.preloading:after .preloading-ani {
  animation-fill-mode: forwards;
  -webkit-animation: preloadingAni 0.8s;
  -moz-animation: preloadingAni 0.8s;
  -ms-animation: preloadingAni 0.8s;
  -o-animation: preloadingAni 0.8s;
  animation: preloadingAni 0.8s;
}
.orientation-portrait {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: #fff;
  display: none;
  background: url("/official/images/pc/fanzhuan_bg.png") 50% no-repeat;
  background-size: cover;
  -webkit-transition: all 0;
  -moz-transition: all 0;
  -ms-transition: all 0;
  -o-transition: all 0;
  transition: all 0;
}
.orientation-portrait div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  background: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0;
  -moz-transition: all 0;
  -ms-transition: all 0;
  -o-transition: all 0;
  transition: all 0;
}
.orientation-portrait div img {
  width: 693px;
  height: 243px;
  margin-top: -200px;
}
@media screen and (max-height: 854px) {
  .index .popup .popup_box {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
  }
  .index .content-02 .main {
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    -ms-transform: scale(0.75);
    -o-transform: scale(0.75);
    transform: scale(0.75);
  }
  .index .content-02 .title-02 {
    top: -30px;
    transform: scale(0.75);
  }
  .index .content-01 .banner {
    transform: translateX(-50%) scale(0.9);
  }
  .index .content-01 .title {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  .index .content-04 .title {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
    top: 25px;
  }
  .index .content-04 .main {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
  }
  .index .content-04 .enclosure {
    bottom: -20px;
  }
}
@media screen and (max-height: 810px) {
  .preloading {
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
  }
  .index .popup .popup_box {
    -webkit-transform: scale(0.85);
    -moz-transform: scale(0.85);
    -ms-transform: scale(0.85);
    -o-transform: scale(0.85);
    transform: scale(0.85);
  }
  .index .content-01 {
    background: #a4e4f8;
  }
  .index .content-01 .title {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  .index .content-01 .banner {
    transform: translateX(-50%) scale(0.75);
    margin-top: -270px;
  }
  .index .content-01 .bg {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
  }
  .index .content-02 .main {
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
  }
  .index .content-02 .enclosure {
    bottom: -50px;
  }
  .index .content-02 .treasure-02 {
    bottom: 32px;
  }
  .index .content-02 .title-02 {
    transform: scale(0.7);
  }
  .index .content-04 .main {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
  }
}
@media screen and (max-height: 768px) {
  .index .header {
    min-width: auto;
  }
  .index .swiper-slide .separate {
    top: -20px;
  }
  .index .swiper-slide .title {
    top: 10px;
  }
  .index .content-01 .title {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  .index .content-04 .enclosure {
    bottom: -40px;
  }
  .index .popup .popup_box,
  .index .swiper-slide .title {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
  }
  .index .content-02 .enclosure {
    bottom: -50px;
  }
  .index .content-02 .separate {
    top: -35px;
  }
  .index .content-02 .treasure-01 {
    bottom: 70px;
  }
  .index .content-02 .treasure-02 {
    bottom: 17px;
  }
  .index .content-02 .title-02 {
    top: -54px;
    transform: scale(0.65);
  }
  .index .content-04 .main {
    -webkit-transform: scale(0.72);
    -moz-transform: scale(0.72);
    -ms-transform: scale(0.72);
    -o-transform: scale(0.72);
    transform: scale(0.72);
    margin: -210px 0 0 -356px;
  }
  .index .content-04 .amazed-snail {
    left: 405px;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
  }
  .index .content-05 {
    height: 166px;
  }
  .preloading {
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    -ms-transform: scale(0.75);
    -o-transform: scale(0.75);
    transform: scale(0.75);
  }
}
@media screen and (max-height: 730px) {
  .index .content-02 .main {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
  }
  .index .content-02 .enclosure {
    bottom: -50px;
  }
  .index .content-02 .separate {
    top: -50px;
  }
  .index .content-02 .treasure-01 {
    bottom: 60px;
  }
  .index .content-02 .treasure-02 {
    bottom: 5px;
  }
  .index .content-02 .title-02 {
    top: -70px;
  }
  .index .content-04 .enclosure {
    bottom: -75px;
  }
}
@media screen and (max-height: 650px) {
  .preloading {
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    -ms-transform: scale(0.75);
    -o-transform: scale(0.75);
    transform: scale(0.75);
  }
  .header .icon {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
  }
  .index .content-01 .banner {
    transform: translateX(-50%) scale(0.65);
    margin-top: -337px;
  }
  .index .content-01 .bg {
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    -ms-transform: scale(0.75);
    -o-transform: scale(0.75);
    transform: scale(0.75);
  }
  .index .content-02 .main {
    -webkit-transform: scale(0.65);
    -moz-transform: scale(0.65);
    -ms-transform: scale(0.65);
    -o-transform: scale(0.65);
    transform: scale(0.65);
  }
  .index .content-02 .separate {
    top: -60px;
  }
  .index .content-02 .enclosure {
    bottom: -60px;
  }
  .index .content-02 .enclosure .treasure-01 {
    bottom: 60px;
  }
  .index .content-02 .treasure-02 {
    bottom: 30px;
  }
  .header .hidden-link-box {
    right: 0;
  }
}
@media screen and (max-height: 550px) {
  .index .content-01 .banner {
    transform: translateX(-50%) scale(0.52);
    margin-top: -350px;
  }
  .index .content-01 .bg {
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    -ms-transform: scale(0.6);
    -o-transform: scale(0.6);
    transform: scale(0.6);
  }
  .index .popup .popup_box,
  .index .swiper-slide .title,
  .index .content-04 .main {
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    -ms-transform: scale(0.6);
    -o-transform: scale(0.6);
    transform: scale(0.6);
  }
  .index .content-02 .main {
    transform: scale(0.55);
    margin-top: -359px;
  }
  .index .content-01 .banner {
    transform: translateX(-50%) scale(0.6);
  }
}
@media screen and (max-width: 1880px) {
  .index .reward {
    right: 10%;
  }
}
@media screen and (max-width: 1780px) {
  .index .reward {
    right: 5%;
  }
}
@media screen and (max-width: 1560px) {
  .index .content-01 .banner {
    transform: translateX(-50%) scale(0.8);
  }
  .index .content-01 .bg {
    transform: scale(0.9);
  }
  .preloading {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
  }
}
@media screen and (max-width: 1366px) {
  .index .content-01 .bg {
    transform: scale(0.95);
  }
  .preloading {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
  }
  .index .content-02 .main {
    transform: scale(0.65);
  }
  .index .content-01 .banner {
    transform: translateX(-50%) scale(0.9);
  }
  .index .content-04 .amazed-snail {
    left: 450px;
    transform: scale(0.75);
  }
  .index .rocket {
    right: auto;
    left: 990px;
  }
}
@media screen and (max-width: 1200px) {
  .index .content-01 .banner {
    transform: translateX(-50%) scale(0.75);
    margin-top: -340px;
  }
  .index .content-02 .main {
    transform: scale(0.6);
  }
  .index .content-04 .amazed-snail {
    left: 450px;
    transform: scale(0.75);
  }
  .index .content-01 .title {
    transform: scale(0.8);
  }
  .index .content-02 .title,
  .index .content-04 .title {
    transform: scale(0.75);
    top: 5px;
  }
  .index .rocket {
    right: auto;
    left: 990px;
  }
}
@media (orientation: portrait) {
  .index {
    opacity: 1;
  }
  .orientation-portrait {
    display: block;
  }
}
.turn {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
@keyframes ani4 {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  80% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
  }
}
@keyframes rotateAni {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    -ms-transform: rotate(3deg);
    -o-transform: rotate(3deg);
    transform: rotate(3deg);
    opacity: 0.2;
    filter: alpha(opacity=20);
  }
}
@keyframes huobaAni {
  from {
    background-position: 0px 0px;
  }
  to {
    background-position: -1950px 0px;
  }
}
@keyframes playBtnAni {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
  }
}
@keyframes preloadingAni {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -ms-transform: scale(2);
    -o-transform: scale(2);
    transform: scale(2);
  }
}
@keyframes Beat {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  60% {
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
  }
}
@keyframes rocket {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(5px);
  }
  75% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes rocketAni {
  from {
    background-position: 0px 0px;
  }
  to {
    background-position: -1600px 0px;
  }
}
@keyframes rubberBand2 {
  0% {
    -webkit-transform: translateY(0) scale3d(1, 1, 1);
    transform: translateY(0) scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: translateY(-50px) scale3d(1.25, 0.75, 1);
    transform: translateY(-50px) scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: translateY(-55px) scale3d(0.75, 1.25, 1);
    transform: translateY(-55px) scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: translateY(-50px) scale3d(1.15, 0.85, 1);
    transform: translateY(-50px) scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: translateY(0) scale3d(1, 1, 1);
    transform: translateY(0) scale3d(1, 1, 1);
  }
}
@keyframes WobbleAni2 {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(-2deg);
    -moz-transform: rotate(-2deg);
    -ms-transform: rotate(-2deg);
    -o-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
}
@keyframes WobbleAni3 {
  0% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  60% {
    -webkit-transform: translate(-10px, 10px);
    -moz-transform: translate(-10px, 10px);
    -ms-transform: translate(-10px, 10px);
    -o-transform: translate(-10px, 10px);
    transform: translate(-10px, 10px);
  }
}
@keyframes mouse {
  0% {
    -webkit-transform: translateX(0px);
  }
  20% {
    -webkit-transform: translateX(-400px);
  }
  30% {
    -webkit-transform: translateX(-400px);
  }
  70% {
    -webkit-transform: translateX(-2200px);
  }
  100% {
    -webkit-transform: translateX(-2200px);
  }
}
/*老鼠动画*/
@keyframes mouseAni1 {
  from {
    background-position: 0px 0px;
  }
  to {
    background-position: -136px 0px;
  }
}
